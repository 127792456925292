<template>
    <ConfirmOperation v-bind="data[order.operation]" />
</template>

<script setup>
import ConfirmOperation from '@/components/transactional-funds/Shared/ConfirmOperation.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION, ARCHITECTURE, MARKET } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
});

const showOutOfScheduleMsg = computed(() => {
    const isInstrument =
        props.item.architecture === ARCHITECTURE.NAV ||
        order.value.market === MARKET.NAV;
    const isOut =
        !isMarketOpen.value?.open && isMarketOpen.value?.overTimeEnabled;
    return isInstrument && isOut;
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: 'Vas a invertir',
        amount: order.value.amount - (order.value.commission + order.value.iva),
        format: order.value.currency,
        headline: `en ${props.item.info.name}`,
        downloads: Object.values(props.item.config.downloads),
        messages: [],
        info: [
            [
                {
                    title: t('transactions.commissionAndIVA'),
                    value: {
                        amount: order.value.commission + order.value.iva,
                        format: order.value.currency,
                    },
                },
                {
                    title: t('transactions.totalReservation'),
                    value: {
                        amount: order.value.amount,
                        format: order.value.currency,
                    },
                },
            ],
            [
                {
                    title: t('transactions.account'),
                    value: {
                        label: currentAccount.value.idCuentaGrupo,
                    },
                },
                {
                    title: t('transactions.entryDate'),
                    value: {
                        label: (() => {
                            if (isMarketOpen.value?.open) {
                                return formatDate(
                                    isMarketOpen.value?.nextOpen,
                                    'dayOfWeek'
                                );
                            }

                            return formatDate(
                                isMarketOpen.value?.afterNextOpen,
                                'dayOfWeek'
                            );
                        })(),
                    },
                },
                {
                    title: t('transactions.afterInvestBalance'),
                    value: {
                        amount:
                            balance.value[order.value.currency] -
                            order.value.amount -
                            (order.value.commission ?? 0) -
                            (order.value.iva ?? 0),
                        format: order.value.currency,
                    },
                    showZero: true,
                },
            ],
        ],
    },
    [OPERATION.SELL]: {
        title: 'Vas a rescatar',
        amount: order.value.quantity,
        format: 'PLAIN',
        measure: 'cuotas',
        headline: `de ${props.item.info.name}`,
        downloads: [],
        messages: (() => {
            const _messages = [];

            if (showOutOfScheduleMsg.value) {
                _messages.push(
                    'Operación fuera de horario. (Horario límite). Te reservaremos el monto hoy y la orden será ingresada a primera hora del próximo día hábil.'
                );
            }

            return _messages;
        })(),
        info: [
            [
                {
                    title: t('transactions.estRescue'),
                    value: {
                        amount: order.value.quantity * order.value.price,
                        format: order.value.currency,
                    },
                },
            ],
            [
                {
                    title: t('transactions.account'),
                    value: {
                        label: currentAccount.value.idCuentaGrupo,
                    },
                },
                {
                    title: t('transactions.entryDate'),
                    value: {
                        label: (() => {
                            if (isMarketOpen.value?.open) {
                                return formatDate(new Date(), 'dayOfWeek');
                            }

                            return formatDate(
                                isMarketOpen.value?.nextOpen,
                                'dayOfWeek'
                            );
                        })(),
                    },
                },
                {
                    title: t('transactions.liquidityPeriod'),
                    value: {
                        label: t(
                            'transactions.day',
                            props.item?.config.rescTime
                        ),
                    },
                },
            ],
        ],
    },
}));
</script>
