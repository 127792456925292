<template>
    <div class="footer__container">
        <OrqProgressIndicator
            :inverse="inverse"
            class="footer__progress"
            :items="items"
        />
        <div class="footer__step-controls">
            <div class="footer__buttons">
                <OrqButton
                    size="compact"
                    :label="t('transactions.success.accept')"
                    :disabled="loading"
                    appearance="primary"
                    :loading="loading"
                    :icon-name="loading ? 'refresh' : ''"
                    @click="$emit('accept')"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { OrqButton } from '@digital/orquestra';
import { OrqProgressIndicator } from '@orquestra-web/vue';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    steps: {
        type: Number,
        default: 1,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

defineEmits(['accept', 'home']);

const items = computed(() => {
    return [...Array(props.steps)].map(() => {
        return { active: true };
    });
});
</script>

<style lang="scss" scoped>
.footer {
    &__container {
        display: flex;
        flex-direction: column;
    }
    &__step-controls {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
    &__progress {
        margin-inline: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    &__cancel {
        cursor: pointer;
        text-decoration: underline;
    }
}

:deep(.orq-progress-indicator__bar__item--active--inverse) {
    background-color: var(--action-secondary-selected-inverse) !important;
}
</style>
