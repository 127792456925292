<template>
    <div class="footer__container">
        <OrqProgressIndicator
            :inverse="inverse"
            class="footer__progress"
            :items="items"
        />
        <div class="footer__step-controls">
            <span
                class="footer__cancel body-baseline"
                :class="{
                    'text--emphasis-base-high': !inverse,
                    'text--emphasis-base-high-inverse': inverse,
                }"
                @click="onClose()"
                >{{ t('transactions.cancel') }}</span
            >
            <div class="footer__buttons">
                <OrqButton
                    v-if="modelValue > 0 && back"
                    appearance="line"
                    size="compact"
                    :label="t('transactions.back')"
                    :inverse="inverse"
                    @click="$emit('back')"
                />
                <OrqButton
                    size="compact"
                    :label="nextLabel ?? t('transactions.next')"
                    :disabled="!nextEnabled || loading"
                    appearance="primary"
                    :inverse="!nextEnabled || loading ? inverse : false"
                    :loading="loading"
                    :icon-name="loading ? 'refresh' : ''"
                    @click="$emit('next')"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { OrqButton } from '@digital/orquestra';
import { OrqProgressIndicator } from '@orquestra-web/vue';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
};

const props = defineProps({
    steps: {
        type: Number,
        default: 1,
    },
    modelValue: {
        type: Number,
        default: 0,
    },
    nextEnabled: {
        type: Boolean,
        default: true,
    },
    nextLabel: {
        type: String,
        default: null,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    back: {
        type: Boolean,
        default: true,
    },
});

defineEmits(['update:modelValue', 'cancel', 'next', 'back']);

const items = computed(() => {
    return [...Array(props.steps)].map((_, index) => {
        if (index <= props.modelValue) {
            return { active: true };
        }
        return { active: false };
    });
});
</script>

<style lang="scss" scoped>
.footer {
    &__container {
        display: flex;
        flex-direction: column;
    }
    &__step-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
    &__progress {
        margin-inline: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    &__cancel {
        cursor: pointer;
        text-decoration: underline;
    }
}

:deep(.orq-progress-indicator__bar__item--active--inverse) {
    background-color: var(--action-secondary-selected-inverse) !important;
}
</style>
