import { TYPES, ARCHITECTURE, OPERATION } from '@/consts';

import MaintenanceMode from '@/components/transactional-funds/Shared/MaintenanceMode.vue';
import MarketClosed from '@/components/transactional-funds/Shared/MarketClosed.vue';
import NotAvailable from '@/components/transactional-funds/Shared/NotAvailable.vue';
import NotQualified from '@/components/transactional-funds/Shared/NotQualified.vue';

import Case1Buy from '@/components/transactional-funds/Case1/InitOperation.vue';
import Case2Buy from '@/components/transactional-funds/Case2/InitOperation.vue';
import Case3Buy from '@/components/transactional-funds/Case3/InitOperation.vue';
import Case5Buy from '@/components/transactional-funds/Case5/InitOperation.vue';
import DAPBuy from '@/components/transactional-funds/DAP/DAPBuy.vue';
import SharesBuy from '@/components/productos-de-inversion/acciones/Transactional/InitOperation.vue';
import DolarBuy from '@/components/productos-de-inversion/dolar/transactional/InitOperation.vue';

import Case1Sell from '@/components/transactional-funds/Case1/FundSell.vue';
import Case2Sell from '@/components/transactional-funds/Case2/FundSell.vue';
import Case3Sell from '@/components/transactional-funds/Case3/FundSell.vue';
import Case5Sell from '@/components/transactional-funds/Case5/FundSell.vue';
import SharesSell from '@/components/productos-de-inversion/acciones/Transactional/FundSell.vue';
import DolarSell from '@/components/productos-de-inversion/dolar/transactional/FundSell.vue';

import Case1Confirm from '@/components/transactional-funds/Case1/ConfirmOperation.vue';
import Case2Confirm from '@/components/transactional-funds/Case2/ConfirmOperation.vue';
import Case3Confirm from '@/components/transactional-funds/Case3/ConfirmOperation.vue';
import Case5Confirm from '@/components/transactional-funds/Case5/ConfirmOperation.vue';
import DAPConfirm from '@/components/transactional-funds/DAP/ConfirmOperation.vue';
import SharesConfirm from '@/components/productos-de-inversion/acciones/Transactional/ConfirmOperation.vue';
import DolarConfirm from '@/components/productos-de-inversion/dolar/transactional/ConfirmOperation.vue';

import Case1Completed from '@/components/transactional-funds/Case1/OperationCompleted.vue';
import Case2Completed from '@/components/transactional-funds/Case2/OperationCompleted.vue';
import Case3Completed from '@/components/transactional-funds/Case3/OperationCompleted.vue';
import Case5Completed from '@/components/transactional-funds/Case5/OperationCompleted.vue';
import DAPCompleted from '@/components/transactional-funds/DAP/OperationCompleted.vue';
import SharesCompleted from '@/components/productos-de-inversion/acciones/Transactional/OperationCompleted.vue';
import SharesClosed from '@/components/productos-de-inversion/acciones/Transactional/MarketClosed.vue';
import DolarCompleted from '@/components/productos-de-inversion/dolar/transactional/OperationCompleted.vue';

import Case1Closed from '@/components/transactional-funds/Case1/MarketClosed.vue';
import Case2Closed from '@/components/transactional-funds/Case2/MarketClosed.vue';
import Case3Closed from '@/components/transactional-funds/Case3/MarketClosed.vue';
import Case5Closed from '@/components/transactional-funds/Case5/MarketClosed.vue';
import DolarClosed from '@/components/productos-de-inversion/dolar/transactional/MarketClosed.vue';

import { useUserStore } from '@/_store/user.store';

import { useStatusStore } from '@/_store/status.store';
import { storeToRefs } from 'pinia';

const components = {
    MaintenanceMode: {
        desc: 'Maintenance Mode',
        component: () => MaintenanceMode,
        confirmComponent: MaintenanceMode, // Leave this here because is used on Daps
    },
    MarketClosed: {
        desc: 'Closed',
        component: () => MarketClosed,
    },
    NotAvailable: {
        desc: 'Not available',
        component: () => NotAvailable,
        confirmComponent: NotAvailable,
        closedComponent: NotAvailable, // Leave this here because is used when an instrument.isOpen status = closed
    },
    NotQualified: {
        desc: 'Not qualified',
        component: () => NotQualified,
    },
    Case1: {
        desc: 'caso 1: FM BTG AGF o FI Multitype',
        component: (operation) => {
            return operation === OPERATION.BUY ? Case1Buy : Case1Sell;
        },
        confirmComponent: Case1Confirm,
        completedComponent: Case1Completed,
        closedComponent: Case1Closed,
    },
    Case2: {
        desc: 'caso 2: Fondo de inversion o Fondo mutuo que transa en NAV',
        component: (operation) => {
            return operation === OPERATION.BUY ? Case2Buy : Case2Sell;
        },
        confirmComponent: Case2Confirm,
        completedComponent: Case2Completed,
        closedComponent: Case2Closed,
    },
    Case3: {
        desc: 'caso 3: Fondo de inversion o Fondo mutuo que transa en Bolsa',
        component: (operation) => {
            return operation === OPERATION.BUY ? Case3Buy : Case3Sell;
        },
        confirmComponent: Case3Confirm,
        completedComponent: Case3Completed,
        closedComponent: Case3Closed,
    },
    Case5: {
        desc: 'caso 5: Fondo mutuo INTERNACIONAL',
        component: (operation) => {
            return operation === OPERATION.BUY ? Case5Buy : Case5Sell;
        },
        confirmComponent: Case5Confirm,
        completedComponent: Case5Completed,
        closedComponent: Case5Closed,
    },
    DAP: {
        desc: 'caso DAP',
        component: () => {
            return DAPBuy;
        },
        confirmComponent: DAPConfirm,
        completedComponent: DAPCompleted,
    },
    Shares: {
        desc: 'Shares: Acciones',
        component: (operation) => {
            return operation === OPERATION.BUY ? SharesBuy : SharesSell;
        },
        confirmComponent: SharesConfirm,
        completedComponent: SharesCompleted,
        closedComponent: SharesClosed,
    },
    Dolar: {
        desc: 'moneda: dólar',
        component: (operation) => {
            return operation === OPERATION.BUY ? DolarBuy : DolarSell;
        },
        confirmComponent: DolarConfirm,
        completedComponent: DolarCompleted,
        closedComponent: DolarClosed,
    },
};

export const useInstrument = () => {
    const whatComponent = ({ isAvailable, item }) => {
        const statusStore = useStatusStore();
        const { canTrans } = storeToRefs(statusStore);

        // Shared status
        if (!canTrans.value) {
            return components['MaintenanceMode'];
        }

        // Shared status
        if (!isAvailable) {
            return components['NotAvailable'];
        }

        const userStore = useUserStore();
        if (
            item.config?.qualified === true &&
            userStore.user?.qualified === false
        ) {
            return components['NotQualified'];
        }

        const type = item.info ? item.info.type.id : null;
        const architecture = item.architecture || null;
        const isMultitype = item.config?.multiproduct || false;
        const isBolsa = item.config?.bolsa || false;

        if (isMultitype) {
            return components['Case1'];
        }
        if (type === TYPES.FONDO_MUTUO) {
            if (isBolsa) {
                if (architecture === ARCHITECTURE.NAV) {
                    return components['Case2'];
                }
                return components['Case3'];
            }
            return components['Case1'];
        }
        if (type === TYPES.FONDO_DE_INVERSION) {
            if (architecture === ARCHITECTURE.NAV) {
                return components['Case2'];
            }
            if (architecture === ARCHITECTURE.BOLSA) {
                return components['Case3'];
            }
            if (architecture === ARCHITECTURE.NAV_Y_BOLSA) {
                return item.config.nav
                    ? components['Case2']
                    : components['Case3'];
            }
        }
        if (type === TYPES.FONDO_INTERNACIONAL) {
            return components['Case5'];
        }
        if (type === TYPES.ACCION) {
            return components['Shares'];
        }
        if (type === TYPES.MONEDA) {
            return components['Dolar'];
        }
        if (type === TYPES.DEPOSITO_A_PLAZO) {
            return components['DAP'];
        }

        return 'No component match. This should not happen';
    };

    return {
        whatComponent,
    };
};
