<template>
    <div class="timer__container" :class="{ inverse }">
        <div class="timer__labels">
            <OrqIconContainer name="timer" :size="16" />
            <span class="body-sm text--emphasis-base-high">{{
                t('timer.investTime')
            }}</span>
            <span class="body-sm text--emphasis-base-medium">{{
                timeLeft
            }}</span>
        </div>
        <div class="bar__container bg--neutral-extended-40">
            <div
                class="bar--color bg--informative"
                :style="`height: 100%; width: ${timersStore.getPercent(
                    timerId
                )}%;`"
            ></div>
        </div>
    </div>
</template>

<script setup>
import { OrqIconContainer } from '@orquestra-web/vue';

import { useTimersStore } from '@/_store/timers.store.js';

import { computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const timersStore = useTimersStore();

const props = defineProps({
    timerId: {
        type: String,
        default: 'summary',
    },
    maxSeconds: {
        type: Number,
        default: 60,
    },
    ticksPerSecond: {
        type: Number,
        default: 10,
    },
    reversed: {
        type: Boolean,
        default: false,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['timeUp']);

const timeLeft = computed(() => {
    const secondsLeft = Math.ceil(
        props.maxSeconds - timersStore.getSeconds(props.timerId)
    );
    if (secondsLeft !== 1) {
        return t('timer.seconds', { seconds: secondsLeft });
    }
    return t('timer.second');
});

onMounted(() => {
    timersStore.setTimer(
        props.timerId,
        props.maxSeconds,
        props.maxSeconds,
        props.reversed
    );
});

const timeUp = computed(() => timersStore.timeUp(props.timerId));

watch(timeUp, () => {
    if (timeUp.value) {
        emit('timeUp');
    }
});
</script>

<style lang="scss" scoped>
.timer {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: fit-content;
    }
    &__labels {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
    }
    &__bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        align-items: center;
    }
}
.bar {
    &__container {
        height: 4px;
        width: 236px;
        border-radius: 100px;
        overflow: hidden;
    }
}

.inverse {
    color: var(--on-color-emphasis-high-inverse);
    .body-sm {
        color: var(--on-color-emphasis-high-inverse);
    }
    .bar--color {
        background-color: var(--action-main-enabled);
    }
}
</style>
