<template>
    <OrqLockscreen
        v-if="visible"
        ref="drawerLockScreen"
        class="modal-lockscreen"
        @click="toogleModal"
    />
    <OrqModal
        v-bind="options"
        :visible="visible"
        size="small"
        @on-primary-click="onAccept"
        @on-secondary-click="onClose"
        @on-close="onClose"
    />
</template>

<script setup>
import { OrqModal, OrqLockscreen } from '@orquestra-web/vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { EVENTS } from '@/consts';

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();
const { addListener, removeListeners } = rootEvents;

const props = defineProps({
    lockScreen: {
        type: [Boolean, String],
        default: 'static',
        validator: (value) => [true, false, 'static'].includes(value),
    },
});

const visible = ref(false);
const options = ref({});

const toogleModal = () => {
    if (props.lockScreen === 'static') return;
    onClose();
};

onMounted(() => {
    // listerner added to open intercom
    addListener(EVENTS.CONFIRM, (newOptions) => {
        const defaultOptions = {
            secondaryButtonText: 'Cancelar',
            primaryButtonText: 'Aceptar',
            title: 'Confirmar',
            description: '',
            inverse: false,
            callback: () => {},
        };
        options.value = { ...defaultOptions, ...newOptions };
        visible.value = true;
    });
});

onUnmounted(() => {
    removeListeners();
});

const onClose = () => {
    if (typeof options.value.callback === 'function') {
        options.value.callback(false);
    }
    visible.value = false;
};

const onAccept = () => {
    if (typeof options.value.callback === 'function') {
        options.value.callback(true);
    }
    visible.value = false;
};
</script>

<style lang="scss" scoped>
.modal-lockscreen {
    z-index: 999;
}
</style>
