<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <div class="heading-md">{{ t('withdraw.title') }}</div>
                <div class="heading-lg">
                    Hubo un error al procesar la operación.
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqColumn :gap="8">
                <div class="body-sm text--emphasis-base-medium">
                    Vuelve a intentarlo más tarde. Si el problema persiste o
                    tienes dudas, contáctanos por el chat.
                </div>
                <div>
                    <OrqLink
                        label="Contactar al chat"
                        orientation="right"
                        icon="default"
                        type="primary"
                        @click="openIntercom"
                    />
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <RetirarFooter
                :show-progress="false"
                :show-cancel="false"
                next-label="Cerrar"
                @next="onClose"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { OrqDrawerBody, OrqColumn } from '@digital/orquestra';
import { OrqLink } from '@orquestra-web/vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import RetirarFooter from './shared/RetirarFooter.vue';

import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS } from '@/consts';
const rootEvents = useRootEvents();
const openIntercom = () => {
    rootEvents.emit(EVENTS.INTERCOM_OPEN);
};
const onClose = () => {
    rootEvents.emit(EVENTS.RETIRAR_CLOSE);
};
</script>


