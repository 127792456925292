import api from './api.js';

export default {
    sendInvitation() {
        return api.put('/user/onboardings/new', { data: null });
    },
    getExceptions() {
        return api.get(`user/onboardings/authorization/exception`, { data: null });
    },
    getQuizOnboarding() {
        const path = `user/onboardings/authorization/quiz`;
        return api.get(path, { data: null });
    },
    postQuizOnboarding(payload) {
        const path = `user/onboardings/authorization/quiz`;
        return api.post(path, payload);
    },
    getComplementList() {
        const path = "user/complementos";
        return api.get(path, { data: null });
    },
    /** Init */
    postInitOnboarding() {
        const path = `user/onboardings`;
        return api.post(path, { data: null });
    },
    /** Update */
    putUpdateOnboarding(payload) {
        const path = `user/onboardings`;
        return api.put(path, payload);
    },
    getUserData() {
        const path = `user/onboardings`;
        return api.get(path, { data: null });
    },
    validateDNI({ serie }) {
        const path = `user/onboardings/authorization/ci/${serie}`;
        return api.put(path, { data: null });
    },
    getDataRut({ rut }) {
        const path = `user/onboardings/datosPersona/${rut}`;
        return api.get(path, { data: null });
    },
    getCargosPep() {
        const path = `user/onboardings/select/2`;
        return api.get(path, { data: null });
    },
    getDof() {
        const path = `user/onboardings/select/3`;
        return api.get(path, { data: null });
    },
    getPatrimonioInvertible() {
        const path = `user/onboardings/select/5`;
        return api.get(path, { data: null });
    },
    getProfileSurvey() {
        return api.get("surveys");
    },
    getProfile(answers) {
        return api.put("user/onboardings/survey", answers);
    },
    chooseProfile(profile) {
        return api.put(`user/onboardings/mandato?profile=${profile}`, {
          data: null
        });
    },
    getContractsList() {
        const path = `user/onboardings/contracts`;
        return api.get(path, { data: null });
    },
    getDownloadContract({ codigoSADCO }) {
        const path = `user/onboardings/contracts/${codigoSADCO}`;
        return api.get(path, {
            data: null,
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf"
            }
        });
    },
    requestToken() {
        const path = `user/onboardings/token`;
        return api.put(path, { data: null });
    },
    signContract({ token, time }) {
        const path = `user/onboardings/contracts/${token}/${time}`;
        return api.post(
            path,
            {
                data: null
            },
            {
                responseType: "arraybuffer"
            }
        );
    },
};
