import { defineStore } from 'pinia';
import portfolioService from '../_services/portfolio.service';
import { computed, ref } from 'vue';
import { useAccountsStore } from './accounts.store';
import { TYPES, TYPES_DATA, CURRENCY_FORMAT } from '@/consts';

import { useCurrencyStore } from './currency.store.js';
/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const usePortfolioStore = defineStore('portfolio', () => {
    const loading = ref(false);
    const simulation = ref({});
    const investments = ref({});
    const lastUpdate = ref(null);
    const timestamp = ref(null);

    const getPortfolio = async () => {
        loading.value = true;

        const accountsStore = useAccountsStore();
        const currencyStore = useCurrencyStore();

        /**
         * Portfolio needs data from Accounts store.
         */
        return new Promise((resolve, reject) => {
            // Preventing unhandled error
            if (!accountsStore.selected) {
                loading.value = false;
                reject();
            }

            portfolioService
                .getPortfolioByAccount({
                    account: accountsStore.selected,
                })
                .then((r) => {
                    lastUpdate.value = new Date().toLocaleString('es-CL', {
                        dateStyle: 'long',
                        timeStyle: 'short',
                    });
                    simulation.value = r.data.simulacion;
                    investments.value = r.data.inversiones;
                    investments.value.types.map((type) => {
                        type.items.map((item) => {
                            // Encodes idCode TODO: should be moved
                            if (item.instrument.info.idCode)
                                item.idEncoded = window.btoa(
                                    item.instrument.info.idCode
                                );
                            // The next code adds needed data as it isn't in the payload
                            item.expiration =
                                item.instrument.info.name.split(' - ').at(-1) ??
                                '-';
                            // Note: DAP uf totalMoney comes in UF instead of CLP so we have to make the convertion
                            if (
                                type.id === TYPES.DEPOSITO_A_PLAZO &&
                                item.instrument.config.currency === 'UF'
                            ) {
                                item.percent =
                                    100 *
                                    ((currencyStore.UFPrice * item.totalMoney) /
                                        investments.value.totalInvest);
                            } else {
                                item.percent =
                                    100 *
                                    (item.totalMoney /
                                        investments.value.totalInvest);
                            }

                            item.profitAmount =
                                (item.quantity * item.profit) / 100;
                            // TODO: The next data is dummy and should be changed when real data exists
                            item.productName = item.instrument.info.name
                                .split(' - ')
                                .slice(0, 2)
                                .join(' - ');
                            // item.bank = 'Banco BTG Pactual Chile';
                            // item.custodian = 'Depósito central de valores';
                            // item.expirationAmount = 4344554;
                            // This patches currency if the type needs it
                            if (type.id == TYPES.BILLETERA_ML)
                                item.instrument.config.currency = 'CLP';
                            if (type.id == TYPES.MONEDA)
                                item.instrument.config.currency = 'USD';

                            // This patches currency if value is strange
                            const formattedCurrency =
                                item.instrument.config.currency
                                    .toUpperCase()
                                    .replace(/\s/g, '');
                            item.instrument.config.currency =
                                CURRENCY_FORMAT[formattedCurrency] ??
                                CURRENCY_FORMAT.default;
                        });
                    });
                })
                .catch((e) => {
                    reject(e);
                })
                .finally(() => {
                    timestamp.value = new Date().getTime();
                    loading.value = false;
                    resolve();
                });
        });
    };

    const series = computed(() => {
        if (
            !investments.value?.types ||
            investments.value?.types?.length == 0
        ) {
            return [];
        }
        const data = investments.value?.types
            ?.map((type) => {
                if (Object.values(TYPES).includes(type.id)) {
                    return {
                        name: TYPES_DATA[type.id]['name'],
                        y: type.percent,
                        color:
                            TYPES_DATA[type.id]['color'].length > 0
                                ? TYPES_DATA[type.id]['color']
                                : '#c0c0c0',
                    };
                } else {
                    return {
                        name: type.name,
                        y: type.percent,
                        color: '#c0c0c0',
                    };
                }
            })
            .sort((a, b) => b.y - a.y);

        return {
            name: 'Porcentaje',
            data: data ?? [],
        };
    });

    const orderedInvestments = computed(() => {
        let orderedTypes = investments.value?.types || [];

        // Sort types by amount
        orderedTypes = orderedTypes.sort(
            (a, b) => b.amountActual - a.amountActual
        );

        // Sort items by amount
        orderedTypes.forEach((element) => {
            if (element.items) {
                element.items = element.items.sort(
                    (a, b) => b.totalMoney - a.totalMoney
                );
            }
        });

        orderedTypes = orderedTypes.filter(
            (type) => type.id === TYPES.BILLETERA_ML || type.amountActual > 0
        );

        // Moneda will be previous to the last one if present
        const monedaIndex = orderedTypes.findIndex(
            (item) => item.id === TYPES.MONEDA
        );

        const moneda =
            monedaIndex > -1 ? orderedTypes.splice(monedaIndex, 1) : []; // We remove the item from the original array

        if (moneda[0]) {
            moneda[0].items = walletCustomOrder(moneda[0].items);
        }

        // Billetera should be last, it's always present.
        const billeteraMLIndex = orderedTypes.findIndex(
            (item) => item.id === TYPES.BILLETERA_ML
        );
        const billeteraML =
            billeteraMLIndex >= 0
                ? orderedTypes.splice(billeteraMLIndex, 1)
                : [];

        if (billeteraML[0]) {
            billeteraML[0].items = walletCustomOrder(billeteraML[0].items);
        }

        return [...orderedTypes, ...moneda, ...billeteraML].map((i) => {
            i.color = TYPES_DATA[i.id]?.color || 'red';
            return i;
        });
    });

    const walletCustomOrder = (items) => {
        const retenido = items.find((i) => i.productName === 'Saldo Retenido');
        const pendiente = items.find(
            (i) => i.productName === 'Saldo Pendiente'
        );
        const disponible = items.find(
            (i) => i.productName === 'Saldo Disponible'
        );
        return [disponible, retenido, pendiente];
    };

    return {
        getPortfolio,
        investments,
        orderedInvestments,
        series,
        simulation,
        loading,
        lastUpdate,
    };
});
