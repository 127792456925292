<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader :title="title" :inverse="inverse" />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyInfo :data="investInfo" :inverse="inverse" />
                <MonedaInputBuy
                    :rate="USDRate.value"
                    :inverse="inverse"
                    :balance="balance"
                    :error="errorMsg"
                    @update:amount="onUpdateAmount"
                />
            </OrqColumn>
        </template>

        <template #footer>
            <TimerContainer :id-encoded="item?.idEncoded" :inverse="inverse" />
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid"
                :inverse="inverse"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed } from 'vue';
import TransactionalHeader from '@/components/transactional-funds/TransactionalHeader.vue';
import TransactionalFooter from '@/components/transactional-funds/TransactionalFooter.vue';
import TimerContainer from '@/components/Timer/TimerContainer.vue';
import BodyInfo from '@/components/transactional-funds/body-components/BodyInfo.vue';
import MonedaInputBuy from './MonedaInputBuy.vue';
import { OrqDrawerBody, OrqColumn } from '@digital/orquestra';
import { EVENTS, OPERATION } from '@/consts';
import { useVuelidate } from '@vuelidate/core';
import {
    overZero,
    nonZero,
    minValue,
    maxValue,
} from '@/_helpers/rules.helper.js';

import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

import { useCurrencyStore } from '@/_store/currency.store.js';
const currencyStore = useCurrencyStore();
const { USDRate } = storeToRefs(currencyStore);

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps } = storeToRefs(orderStore);
const { initOrder, nextStep } = orderStore;

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { accounts, currentAccount } = storeToRefs(accountsStore);

import { useTimersStore } from '@/_store/timers.store.js';
const timersStore = useTimersStore();
const { timers } = storeToRefs(timersStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

const portfolioFFMM = computed(() => {
    return currentAccount.value?.prtfolioFFM
        ? currentAccount.value?.prtfolioFFM
        : accounts.value[0].prtfolioFFMM;
});

const { t } = useI18n();

const title = computed(() => {
    return t('moneda.dollarsPurchase');
});

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { useRootEvents } from '@/composables/useRootEvents';
const { addListener } = useRootEvents();
addListener(EVENTS.TRANSACCIONAL_CLOSE, () => {
    timersStore.clearTimer('usdRateTimer');
});

const investInfo = computed(() => {
    const data = [
        {
            title: t('moneda.availableBalance'),
            value: {
                amount: balance.value.CLP,
                format: 'CLP',
            },
        },
        {
            title: t('moneda.minAmount'),
            value: {
                amount: order.value.currencyInputUSD
                    ? props.item?.config?.invMinAmount / USDRate.value.value
                    : props.item?.config?.invMinAmount,
                format: order.value.currencyInputUSD ? 'USD' : 'CLP',
            },
        },
        {
            title: t('moneda.maxAmount'),
            tooltip: t('moneda.maxAmountTooltip', { amount: 'US$ 250.000' }),
            value: {
                amount: order.value.currencyInputUSD
                    ? props.item?.config?.invMaxAmount / USDRate.value.value
                    : props.item?.config?.invMaxAmount,
                format: order.value.currencyInputUSD ? 'USD' : 'CLP',
            },
        },
    ];
    return data;
});

const rules = computed(() => {
    const amountRules = {
        balanceOverZero: overZero(balance.value[order.value.currency]),
        moreThanMinInv: minValue(
            props.item?.config?.invMinAmount,
            t('transactions.insufficientAmount')
        ),
        lessThanBalance: maxValue(balance.value[order.value.currency]),
        lessThanMaxInv: maxValue(props.item?.config?.invMaxAmount,  t('transactions.moreThanMax')),
        nonZero: nonZero(),
    };

    return {
        order: {
            amount: amountRules,
        },
    };
});

const v$ = useVuelidate(rules, { order });
const errorMsg = computed(() => v$.value.$errors[0]?.$message ?? null);

const isValid = computed(() => {
    return (
        v$.value?.order.$anyDirty &&
        v$.value?.$errors.length === 0 &&
        !timers.value?.usdRateTimer.timeUp
    );
});

const onUpdateAmount = () => {
    v$.value.$touch();
};

initOrder({
    idEncoded: props.item?.idEncoded,
    prtfolioFFM: portfolioFFMM.value,
    currency: 'CLP',
    rate: USDRate.value,
    showTimer: true,
    operation: OPERATION.BUY,
});
</script>

<style lang="scss" scoped>
.separator {
    &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>
