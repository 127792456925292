import api from './api.js';

export default {
    /**
     * There are some examples. Please delete or modify them as you need
     *  @see https://axios-http.com/docs/intro
     */
    get() {
        const path = 'user';
        return api.get(path, { data: null });
    },
    update(payload) {
        const path = 'user';

        const credentials = {
            phone: payload?.phone,
            password: payload?.password,
            email: payload?.email,
        };

        const _extraData = payload?.extraData;
        const extraData = JSON.stringify({
            tipoDireccion: _extraData?.tipoDireccion,
            direccion: _extraData?.direccion,
            numero: _extraData?.numero,
            comunaTO: _extraData?.comunaTO,
            tipoComplemento: _extraData?.tipoComplemento,
            complemento: _extraData?.complemento,
            nacionalidad: _extraData?.nacionalidad,
            estadoCivil: _extraData?.estadoCivil,
            pais: _extraData?.pais,
        });

        return api.put(path, { ...credentials, extraData });
    },
    // getItem({ id }) {
    //     const path = `path/${id}`;
    //     return api.get(path, { data: null });
    // },
    // postItem({ param }) {
    //     const path = `path`;
    //     return api.post(path, { param });
    // },
    getPosition({ idBtg, account }) {
        const path = `user/position/${idBtg}/${account}`;
        return api.get(path, { data: null });
    },
    getNotifications() {
        const path = `user/notifications`;
        return api.get(path, { data: null });
    },
    setNotification({ id, enabled }) {
        const path = `user/notifications/${id}`;
        return api.put(path, {
            mail: enabled,
            push: enabled,
        });
    },
    getCertificates() {
        return api.get('user/TaxCertificates', { data: null });
    },
    downloadCertificate({ id }) {
        const path = `user/TaxCertificates/${id}`;
        return api.get(path, {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
    downloadCertificates() {
        return api.get('user/TaxCertificates/descargar', {
            data: null,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    },
};
