<template>
    <ConfirmOperation v-bind="data[order.operation]" />
</template>

<script setup>
import ConfirmOperation from '@/components/transactional-funds/Shared/ConfirmOperation.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    balance: {
        type: Number,
        default: 0,
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: t('transactions.youWillInvest'),
        amount: order.value.price * order.value.quantity,
        format: order.value.currency,
        headline: t('transactions.in', { name: props.item.info.symbol }),
        messages: [
            t('transactions.orderValidity', {
                endTime: props.item?.config?.invEndTime,
            }),
        ],
        info: [
            [
                {
                    title: t('transactions.sharesQuantity'),
                    value: {
                        amount: order.value.quantity,
                        format: 'PLAIN',
                    },
                },
                {
                    title:
                        order.value.selectedPrice === 'peak'
                            ? t('transactions.price')
                            : t('transactions.limitPrice'),
                    value: {
                        amount: order.value.price,
                        format:
                            order.value.currency === 'CLP'
                                ? 'CLPEXT'
                                : order.value.currency,
                    },
                },
                {
                    title: t('transactions.commissionAndIVA'),
                    value: {
                        amount: order.value.commission + order.value.iva,
                        format: order.value.currency,
                    },
                },
                {
                    title: t('transactions.totalReservation'),
                    value: {
                        amount: order.value.amount, // We are getting commission + iva
                        format: order.value.currency,
                    },
                },
            ],
            [
                {
                    title: t('transactions.account'),
                    value: {
                        label: currentAccount.value.idCuentaGrupo,
                    },
                },
                {
                    title: t('transactions.entryDate'),
                    value: {
                        label: (() => {
                            return formatDate(Date.now(), 'dayOfWeek');
                        })(),
                    },
                },
                {
                    title: t('transactions.afterInvestBalance'),
                    value: {
                        amount: props.balance - order.value.amount,
                        format: order.value.currency,
                    },
                },
            ],
        ],
    },
    [OPERATION.SELL]: {
        title: t('transactions.youWillSellBolsa'),
        amount: order.value.quantity,
        format: 'PLAIN',
        measure: t('transactions.quotasShares', order.value.quantity),
        headline: t('transactions.of', { name: props.item.info.symbol }),
        downloads: [],
        messages: [
            t('transactions.orderValidity', {
                endTime: props.item?.config?.rescEndTime,
            }),
        ],
        info: [
            [
                {
                    title:
                        order.value.selectedPrice === 'peak'
                            ? t('transactions.price')
                            : t('transactions.limitPrice'),
                    value: {
                        amount: order.value.price,
                        format: order.value.currency === 'CLP'? 'CLPEXT': order.value.currency,
                    },
                },
                {
                    title: t('transactions.commissionAndIVA'),
                    value: {
                        amount: order.value.commission + order.value.iva,
                        format: order.value.currency,
                    },
                },
                {
                    title: t('transactions.totalProfit'),
                    value: {
                        amount:
                            order.value.quantity * order.value.price -
                            (order.value.commission + order.value.iva),
                        format: order.value.currency,
                    },
                },
            ],
            [
                {
                    title: t('transactions.account'),
                    value: {
                        label: currentAccount.value.idCuentaGrupo,
                    },
                },
                {
                    title: t('transactions.entryDate'),
                    value: {
                        label: formatDate(new Date(), 'dayOfWeek'),
                    },
                },
            ],
        ],
    },
}));
</script>


