<template>
    <OrqDrawerBody>
        <template #header>
            <OrqColumn :gap="16">
                <!-- <div class="heading-md">{{ t('pay.title') }}</div> -->
                <div class="heading-lg">
                    {{ t('pay.notAvailable.title') }}
                </div>
            </OrqColumn>
        </template>

        <template #body>
            <OrqNotificationFixed
                notification-type="fixed"
                kind="warning"
                :label-size="14"
                :label="notificationBody"
            >
            <div class="notification body-sm">
                    <div>{{ notificationBody }}</div>
                    <OrqLink
                        :label="t('transactions.contactChat')"
                        icon="default"
                        :inverse="inverse"
                        @click="rootEvents.emit(EVENTS.INTERCOM_OPEN)"
                    />
                </div>
            </OrqNotificationFixed>
        </template>

        <template #footer>
            <AbonarFooter
                :show-progress="false"
                :show-cancel="false"
                next-label="Cerrar"
                @next="onClose"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import {
    OrqDrawerBody,
    OrqColumn,
    OrqNotificationFixed,
} from '@digital/orquestra';
import { OrqLink } from '@orquestra-web/vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import AbonarFooter from './shared/AbonarFooter.vue';

import { EVENTS } from '@/consts';
import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

const onClose = () => {
    rootEvents.emit(EVENTS.ABONAR_CLOSE);
};

const notificationBody = computed(() => {
    return ["6A", "7"].includes(currentAccount.value.tipoCuenta) ? t('transactions.notCuenta4') : t('pay.notAvailable.message');
});
</script>

<style lang="scss" scoped>
.notification {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
</style>

