<template>
    <ConfirmOperation
        v-bind="data[order.operation]"
        :back="back"
        :inverse="inverse"
    />
</template>

<script setup>
import ConfirmOperation from '@/components/transactional-funds/Shared/ConfirmOperation.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    back: {
        type: Boolean,
        default: true,
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: t('transactions.youWillInvest'),
        amount: order.value?.simulation.inversion,
        format: order.value.simulation.moneda,
        headline: t('transactions.in', { name: props.item.info.name }),
        downloads: Object.values(props.item.config.downloads),
        messages: [],
        info: [
            [
                {
                    title: t('transactions.account'),
                    value: {
                        label: currentAccount.value.idCuentaGrupo,
                    },
                },
                {
                    title: t('transactions.entryDate'),
                    value: {
                        label: formatDate(
                            new Date().setDate(
                                new Date().getDate() +
                                    props.item?.config?.invTime ?? 0
                            ),
                            'dayOfWeek'
                        ),
                    },
                },
                {
                    title: t('transactions.afterInvestBalance'),
                    value: {
                        amount:
                            balance.value['CLP'] -
                            order.value.simulation.inversionMonedaOri,
                        format: 'CLP',
                    },
                    showZero: true,
                },
            ],
        ],
    },
}));
</script>
