<template>
    <div
        class="doc__container"
        :class="{ loading, inverse }"
        :style="`border-color: var(--outline${inverse ? '-inverse' : ''})`"
        @click="!loading ? openLink() : null"
    >
        <div class="doc__label-container" :class="{ inverse }">
            <OrqIconContainer :name="extensionIcon" :size="24" />
            <span
                v-if="label"
                class="label-sm"
                :class="{
                    'text--emphasis-base-medium': !inverse,
                    'text--emphasis-base-medium-inverse': inverse,
                }"
                >{{ label }}</span
            >
        </div>
        <div class="download-icon">
            <OrqIconContainer
                :name="loading ? 'refresh' : downloadIcon"
                :size="24"
                :color="`emphasis-base-high${inverse ? '-inverse' : ''}`"
            />
        </div>
    </div>
</template>

<script setup>
import { OrqIconContainer } from '@orquestra-web/vue';

const emit = defineEmits(['click']);

const props = defineProps({
    extensionIcon: {
        type: String,
        default: 'doc-pdf',
    },
    downloadIcon: {
        type: String,
        default: 'download',
    },
    label: {
        type: String,
        default: null,
    },
    link: {
        type: String,
        default: null,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const openLink = () => {
    emit('click');
    if (!props.link) return;
    window.open(props.link, '_blank');
};
</script>

<style lang="scss" scoped>
.doc {
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        border: 1px solid;
        padding: 16px;
        gap: 8px;
        cursor: pointer;
    }
    &__label-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
}

.loading {
    background: var(--neutral-extended-20);
    filter: grayscale(100%);

    .download-icon:deep(.icon-container) {
        animation: rotation 1s infinite linear;
    }
}

.inverse {
    :deep(.icon-container) {
        .icon-container__icon:before {
            color: var(--neutral-base) !important;
        }
    }
    &.loading {
        background: var(--neutral-extended-80);
    }
}
@keyframes rotation {
    from {
        transform: scaleX(-1) rotate(360deg);
    }

    to {
        transform: scaleX(-1) rotate(0deg);
    }
}
</style>
