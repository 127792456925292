<template>
    <Teleport to="#modals">
        <div class="order-completed bg--background-base">
            <div
                class="order-completed__header bg--primary-extended-90 text--emphasis-base-high-inverse"
            >
                <div class="order-completed__container">
                    <OrqColumn>
                        <h1 class="body-baseline">
                            {{ t('transactions.error.notRequestedOperation') }}
                        </h1>
                        <div>
                            <h2 class="heading-2xl">
                                {{ t('transactions.error.title') }}
                            </h2>
                            <div v-if="item?.info" class="heading-lg">
                                {{
                                    t('transactions.error.headline', {
                                        instrument: item?.info.name,
                                    })
                                }}
                            </div>
                        </div>
                    </OrqColumn>
                </div>
            </div>

            <div class="order-completed__body">
                <div class="order-completed__container">
                    <OrqColumn :gap="48">
                        <div class="contact-container">
                            <p class="body-baseline text--emphasis-base-medium">
                                {{ t('transactions.error.tryAgainLater') }}
                            </p>

                            <OrqLink
                                size="large"
                                icon="default"
                                :label="t('transactions.error.contactChat')"
                                @click="rootEvents.emit(EVENTS.INTERCOM_OPEN)"
                            />
                        </div>

                        <div class="order-completed__actions">
                            <OrqButton
                                v-if="showClose"
                                :label="t('transactions.error.close')"
                                @click="onClose()"
                            />
                            <OrqButton
                                :label="t('transactions.success.gotoHome')"
                                appearance="line"
                                @click="
                                    $router.push({
                                        name: 'home',
                                    });
                                    onClose();
                                "
                            />
                        </div>
                    </OrqColumn>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { OrqButton, OrqLink } from '@orquestra-web/vue';
import { OrqColumn } from '@digital/orquestra';
import { EVENTS } from '@/consts';
import { storeToRefs } from 'pinia';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { orderStatus } = storeToRefs(orderStore);

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const onClose = () => {
    orderStatus.value.error = null;
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
};

defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    showClose: {
        type: Boolean,
        default: true,
    },
});
</script>

<style lang="scss" scoped>
.order-completed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 1rem;
    }

    &__header {
        padding: 24px 0;
    }
    &__body {
        padding: 24px 0;
    }
    &__actions {
        display: flex;
        gap: 1rem;
    }
}
.contact-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
</style>
