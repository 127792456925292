<template>
    <OrqColumn>
        <div v-currency-format="currency" class="input-container">
            <OrqInput
                :id="`amountToInvest-${currency}`"
                v-model="inputValue"
                size="large"
                :name="`amountToInvest-${currency}`"
                :label="
                    hideLabel
                        ? null
                        : label
                        ? label
                        : t('transactions.amountToInvest')
                "
                :description="errorMsg ?? description"
                :error="errorMsg"
                :disabled="disabled"
                :inverse="inverse"
                :placeholder="`${FORMAT_PRESETS[currency].preffix}0`"
                :max-length="12"
            />
            <IconImage v-if="icon" class="input-image" :src="icon" :size="24" />
        </div>

        <div v-if="!hideButton">
            <OrqButton
                ref="useBalanceButton"
                appearance="line"
                :label="t('transactions.useAllBalance')"
                size="compact"
                :disabled="disabled || availableBalance <= 0"
                :inverse="inverse"
                @click="useAvailableBalance"
            />
        </div>
    </OrqColumn>
</template>

<script setup>
import { OrqButton } from '@orquestra-web/vue';
import { ref, watch, onMounted } from 'vue';
import { FORMAT_PRESETS } from '@/consts';

import { OrqInput, OrqColumn } from '@digital/orquestra';
import IconImage from '@/components/Shared/IconImage.vue';

import {
    currencyFormat as vCurrencyFormat,
    formatCurrency,
    removeFormat,
} from '@/directives/currencyFormat';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const inputValue = ref(null);

const props = defineProps({
    currency: {
        type: String,
        default: '',
    },
    modelValue: {
        type: Number,
        default: null,
    },
    availableBalance: {
        type: Number,
        default: 0,
    },
    hideLabel: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: null,
    },
    hideButton: {
        type: Boolean,
        default: false,
    },
    description: {
        type: String,
        default: null,
    },
    errorMsg: {
        type: String,
        default: null,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue']);

watch(
    () => props.modelValue,
    () => {
        // if (props.modelValue !== 0) {
        inputValue.value = props.modelValue;
        // }
    },
    {
        immediate: true,
    }
);

// onMounted(() => {
//     // if (props.modelValue !== 0) {
//     //     inputValue.value = formatCurrency(props.modelValue, props.currency);
//     // }
// });

watch(inputValue, (newVal, oldVal) => {
    if (removeFormat(newVal) === removeFormat(oldVal)) {
        return; // Preventing emits when the value is the same
    }
    if (inputValue.value === props.modelValue) {
        return;
    }
    emit('update:modelValue', newVal);
});

const useAvailableBalance = () => {
    inputValue.value = formatCurrency(props.availableBalance, props.currency);
    emit('update:modelValue', props.availableBalance);
};

const useBalanceButton = ref(null);
onMounted(() => {
    // to avoid focus use balance button
    if (useBalanceButton.value) {
        useBalanceButton.value.$el.nextSibling.setAttribute('tabindex', '-1');
    }
});
</script>

<style lang="scss" scoped>
.input {
    &-container {
        position: relative;
    }
    &-image {
        position: absolute;
        right: 0;
        top: 25px;
    }
}
</style>
