<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="t('transactions.unavailableProduct')"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqNotification
                kind="warning"
                type="fixed"
                notification-type="fixed"
                :label-size="14"
                :inverse="inverse"
            >
                <div class="notification body-sm">
                    <span>{{ t('transactions.notQualified') }}</span>
                    <OrqLink
                        :label="t('transactions.notQualifiedMoreInfo')"
                        icon="default"
                        :inverse="inverse"
                        target="_blank"
                        href="https://ayuda.mercadosenlinea.cl/es/articles/8598349-que-es-un-inversionista-calificado"
                    />
                </div>
            </OrqNotification>
        </template>

        <template #footer>
            <OrqFlex class="controls">
                <OrqButton
                    :label="t('transactions.close')"
                    :inverse="inverse"
                    @click="onClose"
                />
            </OrqFlex>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import TransactionalHeader from '../TransactionalHeader.vue';
import { OrqDrawerBody, OrqFlex } from '@digital/orquestra';
import { OrqNotification, OrqButton, OrqLink } from '@orquestra-web/vue';
import { useI18n } from 'vue-i18n';
import { EVENTS } from '@/consts';

const { t } = useI18n();

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

const onClose = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
};

defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" scoped>
.bg--warning-surface {
    background-color: var(--warning-surface);
}
.controls {
    justify-content: end;
}
.notification {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
</style>
