<template>
    <OrqTheme :style="theme" class="custom-scroll">
        <template v-if="loading">
            <div class="lockscreen" />
            <OrqLoader inverse :z-index="9" />
        </template>
        <template v-else>
            <router-view />

            <template v-if="showDrawer">
                <TransaccionalFundsContainer />
                <AbonarContainer />
                <RetirarContainer />
            </template>
        </template>

        <!-- This div is used to render modals @see https://vuejs.org/guide/built-ins/teleport.html#multiple-teleports-on-the-same-target -->
        <div id="modals"></div>

        <!-- This toast is used site wide, please don't delete it -->
        <ToastContainer />

        <!-- This confirm is used site wide, please don't delete it -->
        <ConfirmModal />
        <QuickLoginDrawer />
    </OrqTheme>
</template>

<script setup>
import { OrqTheme, OrqLoader } from '@orquestra-web/vue';
import { useInitApp } from '@/composables/useInitApp.js';
import { useTheme } from '@/composables/useTheme.js';
import { useRouter } from 'vue-router';

import TransaccionalFundsContainer from '@/components/transactional-funds/TransaccionalFundsContainer.vue';
import { computed } from 'vue';
import { useIntercomChat } from './composables/useIntercom';

const { initIntercom } = useIntercomChat();
initIntercom();

import ConfirmModal from '@/components/ConfirmModal/ConfirmModal.vue';

import { useFinmarkets } from '@/composables/useFinmarkets';
import QuickLoginDrawer from './components/MainHeader/QuickLogin/QuickLoginDrawer.vue';

const finmarkets = useFinmarkets();
finmarkets.install();

import { useNotifications } from '@/composables/useNotifications';
useNotifications();

import { useInactivity } from '@/composables/useInactivity';
useInactivity();

const { loading } = useInitApp();
const { theme } = useTheme();

import ToastContainer from '@/components/Toast/ToastContainer.vue';
import AbonarContainer from '@/components/Abonar/AbonarContainer.vue';
import RetirarContainer from './components/Retirar/RetirarContainer.vue';

const router = useRouter();
const showDrawer = computed(() => {
    return !['login'].includes(router.currentRoute.value.name);
});

import { useChangePassword } from '@/composables/useChangePassword';
const { changePassword } = useChangePassword();
changePassword();
</script>

<style lang="scss">
@import './scss/customScroll.scss';
body {
    overscroll-behavior-y: none;
}
/* ORQ override @see https://btgpactual.atlassian.net/browse/NDO-488 */
.orq-link:focus {
    border: none !important;
}
.orq-input.orq-input--focused .orq-input__line {
    background-color: var(--action-secondary-enabled) !important;
}
.orq-input.orq-input--inverse.orq-input--focused .orq-input__line {
    background-color: var(--action-secondary-enabled-inverse) !important;
}
.text-right {
    text-align: right;
}
.ml-auto {
    margin-left: auto;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
<style lang="scss" scoped>
.lockscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background-base-inverse);
    z-index: 1;
}
</style>
