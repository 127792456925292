<template>
    <ConfirmOperation v-bind="data[order.operation]" />
</template>

<script setup>
import ConfirmOperation from '@/components/transactional-funds/Shared/ConfirmOperation.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION, ARCHITECTURE, MARKET } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { currentAccount } = storeToRefs(accountsStore);

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
});

const showOutOfScheduleMsg = computed(() => {
    const isInstrument =
        props.item.architecture === ARCHITECTURE.NAV ||
        order.value.market === MARKET.NAV;
    const isOut =
        !isMarketOpen.value?.open && isMarketOpen.value?.overTimeEnabled;
    return isInstrument && isOut;
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        idEncoded: props.item.idEncoded,
        title: 'Vas a invertir',
        amount: order.value.amount,
        format: order.value.currency,
        headline: t('transactions.in', { name: props.item.info.name }),
        downloads: Object.values(props.item.config.downloads),
        messages: [
            ...(showOutOfScheduleMsg.value
                ? [
                      t('transactions.outOfSchedule', {
                          time: isMarketOpen.value?.closeHour,
                      }),
                  ]
                : []),
        ],
        info: [
            [
                {
                    title: t('transactions.account'),
                    value: {
                        label: currentAccount.value.idCuentaGrupo,
                    },
                },
                {
                    title: t('transactions.entryDate'),
                    value: {
                        label: (() => {
                            return formatDate(Date.now(), 'dayOfWeek');
                        })(),
                    },
                },
                {
                    title: t('transactions.afterInvestBalance'),
                    value: {
                        amount:
                            balance.value[order.value.currency] -
                            order.value.amount,
                        format: order.value.currency,
                    },
                    showZero: true,
                },
            ],
        ],
    },
    [OPERATION.SELL]: {
        idEncoded: props.item.idEncoded,
        title: 'Vas a rescatar',
        amount: order.value.quantity,
        format: 'NONE',
        measure: t('transactions.quotas', Math.ceil(order.value.quantity)),
        headline: t('transactions.of', { name: props.item.info.name }),
        downloads: [],
        info: [
            [
                {
                    title: t('transactions.estRescue'),
                    value: {
                        amount: order.value.amount,
                        format: order.value.currency,
                    },
                },
            ],
            [
                {
                    title: t('transactions.account'),
                    value: {
                        label: currentAccount.value.idCuentaGrupo,
                    },
                },
                {
                    title: t('transactions.entryDate'),
                    value: {
                        label: formatDate(new Date(), 'dayOfWeek'),
                    },
                },
                {
                    title: t('transactions.liquidityPeriod'),
                    value: {
                        label: t(
                            'transactions.day',
                            props.item?.config.rescTime
                        ),
                    },
                },
            ],
        ],
    },
}));
</script>


