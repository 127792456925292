<template>
    <OrqDrawerBody>
        <template #header>
            <img class="header-image" src="/images/clock.png" />
        </template>

        <template #body>
            <OrqColumn>
                <TransactionalHeader
                    :title="title"
                    :inverse="inverse"
                />
                <div class="label" :class="{'label--inverse': inverse}">
                    {{ label }}
                </div>
                <div v-if="hasMirrorIns" class="switch-container">
                    <div class="switch-label">
                        <span
                            class="body-baseline"
                            :class="`text--emphasis-base-high${
                                inverse ? '-inverse' : ''
                            }`"
                            >{{ t('transactions.secondaryMarket') }}</span
                        >

                        <OrqTooltip
                            :inverse="inverse"
                            :label="t('transactions.secondaryMarketTooltip')"
                        >
                            <OrqIconContainer
                                name="info"
                                :size="13"
                                :color="`emphasis-base-high${
                                    inverse ? '-inverse' : ''
                                }`"
                            />
                        </OrqTooltip>
                    </div>
                    <OrqSwitcher
                        v-if="isMirrorOpen"
                        :checked="switcher"
                        :inverse="inverse"
                        @change="switchMirror"
                    />
                    <div v-else :class="{'text--emphasis-base-high-inverse': inverse}">Cerrado</div>
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <OrqFlex class="controls">
                <OrqButton
                    size="compact"
                    :label="t('transactions.close')"
                    :inverse="inverse"
                    @click="onClose"
                />
            </OrqFlex>
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import TransactionalHeader from '../TransactionalHeader.vue';
import {
    OrqDrawerBody,
    OrqFlex,
    OrqTooltip,
    OrqColumn,
} from '@digital/orquestra';
import { OrqButton, OrqSwitcher, OrqIconContainer } from '@orquestra-web/vue';
import { useI18n } from 'vue-i18n';
import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS, OPERATION } from '@/consts';
import { useInstrumentStore } from '@/_store/instrument.store.js';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

const instrumentStore = useInstrumentStore();
const { isMirrorOpen } = storeToRefs(instrumentStore);
const { t } = useI18n();
const rootEvents = useRootEvents();

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    title: {
        type: String,
        default: 'Operación fuera de horario'
    },
    label: {
        type: String,
        default: '',
    },
    link: {
        type: Object,
        default: () => null,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    operation: {
        type: String,
        default: OPERATION.BUY,
    },
});

const hasMirrorIns = computed(() =>
    props.item ? props.item.config?.mirrorIns : false
);

const onClose = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
};

const switcher = ref(false);
const switchMirror = () => {
    if (!props.item) return;
    rootEvents.emit(EVENTS.TRANSACCIONAL_OPEN, {
        idEncoded: props.item.mirrorInsEncoded,
        operation: props.operation,
        inverse: props.inverse,
    });
};
</script>

<style lang="scss" scoped>
.header-image {
    background: #edeef0;
    width: 64px;
    border-radius: 64px;
}

.controls {
    justify-content: end;
}

.switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.switch-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.label {
    color: var(--emphasis-base-medium);
    &--inverse{
        color: var(--emphasis-base-medium-inverse);
    }
}

</style>
