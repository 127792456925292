<template>
    <OperationCompleted v-bind="data[order.operation]" />
</template>

<script setup>
import OperationCompleted from '@/components/transactional-funds/Shared/OperationCompleted.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION } from '@/consts.js';
import { formatDate } from '@/utils/text.js';

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen } = storeToRefs(instrumentStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: t('transactions.success.investLong'),
        amount: order.value.amount - (order.value?.commission + order.value?.iva),
        format: order.value.currency,
        headline: t('transactions.in', { name: props.item.info.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: true,
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: formatDate(new Date(), 'dayOfWeek'),
                },
            },
            {
                title:
                    order.value.selectedPrice === 'peak'
                        ? t('transactions.price')
                        : t('transactions.limitPrice'),
                value: {
                    amount: order.value.price,
                    format:
                        props.item?.config?.currency === 'CLP'
                            ? 'CLPEXT'
                            : props.item?.config?.currency,
                },
                showZero: true,
            },
            {
                title: t('transactions.quotaQuantity'),
                value: {
                    format: 'PLAIN',
                    amount: order.value.quantity,
                },
                showZero: true,
            },
            {
                title: t('transactions.commissionAndIVA'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount:
                        props.item.config.comisionEnabled === false
                            ? 0
                            : order.value?.commission + order.value?.iva,
                },
                showZero: false,
            },
            {
                title: t('transactions.success.total'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount: order.value.amount
                },
                showZero: false,
            },
        ],
    },
    [OPERATION.SELL]: {
        title: t('transactions.success.sellLong'),
        amount: order.value.quantity,
        format: 'PLAIN',
        measure: t('transactions.quotas', order.value.quantity),
        headline: t('transactions.of', { name: props.item.info.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: true,
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: { label: formatDate(new Date(), 'dayOfWeek') },
            },
            {
                title: t('transactions.limitPrice'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount: order.value.price,
                },
            },
            {
                title: t('transactions.commissionAndIVA'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount: order.value?.commission + order.value?.iva,
                },
            },
            {
                title: t('transactions.totalProfit'),
                value: {
                    format: props.item?.config?.currency ?? 'CLP',
                    amount: order.value.amount,
                },
                default: '-',
            },
        ],
    },
}));
</script>


