<template>
    <OrqColumn>
        <BodyInvestAmount
            ref="input0"
            v-model="usdValue"
            :style="`order: ${inputOrder[0]}`"
            currency="USDEXT"
            icon="https://static.btgpactual.cl/apps/flags/US.svg"
            :label="operationLabel ? operationLabel : t('moneda.amountToBuy')"
            :disabled="inputOrder[0] === 3"
            hide-button
            :inverse="inverse"
            :error-msg="error"
            @update:model-value="calculateByInputCurrency"
        />
        <div style="order: 1">
            <OrqButton
                ref="useBalanceButton"
                :label="t('transactions.useAllBalance')"
                appearance="line"
                size="compact"
                :inverse="inverse"
                @click="useBalance()"
            />
        </div>
        <div class="separator-container" style="order: 2">
            <OrqDivider />
            <div>
                <a
                    ref="switchButton"
                    href="#"
                    @click.stop.prevent="switchCurrencies"
                >
                    <OrqIconContainer
                        name="change-user"
                        :size="40"
                        color="emphasis-base-high-inverse"
                        custom-circle-background-color="var(--action-main-enabled)"
                        circle
                    />
                </a>
            </div>
            <OrqDivider />
        </div>
        <BodyInvestAmount
            ref="input3"
            v-model="clpValue"
            :style="`order: ${inputOrder[1]}`"
            currency="CLP"
            icon="https://static.btgpactual.cl/apps/flags/CL.svg"
            :label="t('moneda.amountIn', { currency: 'CLP' })"
            :disabled="inputOrder[1] === 3"
            hide-button
            :error-msg="error"
            :inverse="inverse"
            @update:model-value="calculateByInputCurrency"
        />
    </OrqColumn>
</template>

<script setup>
import { OrqColumn, OrqButton } from '@digital/orquestra';
import { OrqDivider, OrqIconContainer } from '@orquestra-web/vue';
import BodyInvestAmount from '@/components/transactional-funds/body-components/BodyInvestAmount.vue';

import { ref, computed, onMounted, watch } from 'vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { storeToRefs } from 'pinia';

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

import { formatCurrency, removeFormat } from '@/directives/currencyFormat';

const props = defineProps({
    operationLabel: {
        type: String,
        default: null,
    },
    rate: {
        type: Object,
        default: () => {},
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    error: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:amount']);

order.value.currencyInputUSD = true;
const clpValue = ref(null);
const usdValue = ref(null);

// IMPORTANT! This is different from selling bc we send CLP instead of USD
const calculateByInputCurrency = () => {
    if (order.value.currencyInputUSD) {
        const val = Math.ceil(removeFormat(usdValue.value) * props.rate);
        clpValue.value = formatCurrency(val, 'CLP');
        order.value.amount = val;
    } else {
        const val = removeFormat(clpValue.value) / props.rate;
        usdValue.value = formatCurrency(val, 'USD');
        order.value.amount = removeFormat(clpValue.value);
    }
    emit('update:amount');
};

const useBalance = () => {
    clpValue.value = formatCurrency(balance.value.CLP, 'CLP');
    usdValue.value = formatCurrency(balance.value.CLP / props.rate, 'USD');
    order.value.amount = balance.value.CLP;
    emit('update:amount');
};

// Indicates the order of the inputs, changes when pressing the switch button.
const inputOrder = ref([0, 3]);
const switchCurrencies = () => {
    order.value.currencyInputUSD = !order.value.currencyInputUSD;
    inputOrder.value.reverse();
};

const useBalanceButton = ref(null);
const switchButton = ref(null);
const input0 = ref(null);
const input3 = ref(null);

const disabledInput = computed(() =>
    inputOrder.value[1] === 3 ? input3.value : input0.value
);

// to avoid focus use balance button, switch button and disabled input
onMounted(() => {
    if (useBalanceButton.value && switchButton.value && disabledInput.value) {
        useBalanceButton.value.$el.setAttribute('tabindex', '-1');
        switchButton.value.setAttribute('tabindex', '-1');

        watch(
            () => disabledInput.value,
            () => {
                disabledInput.value.$el
                    .querySelector('input')
                    .setAttribute('tabindex', '-1');
            },
            { deep: true, immediate: true }
        );
    }
});
</script>

<style lang="scss" scoped>
.separator {
    &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>
