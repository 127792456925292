<template>
    <OrqLockscreen v-if="show" :z-index="1010" />
    <OrqModal
        :visible="show"
        :show-actions="false"
        :show-one-action="false"
        :title="
            user.extraData.fonoMovil
                ? t('token.title')
                : t('token.noPhoneTitle')
        "
        style="z-index: 1010"
        :inverse="inverse"
        :class="{ inverse }"
        @on-close="onClose"
        @keydown.enter="onSubmit"
    >
        <template #wrapper>
            <OrqColumn>
                <template v-if="user.extraData.fonoMovil || props.obol">
                    <template v-if="type === TOKEN_TYPE.SMS">
                        <p>{{ t('token.dialog.sms') }}</p>
                    </template>
                    <template v-if="type === TOKEN_TYPE.SOFTTOKEN">
                        <p>{{ t('token.dialog.softtoken') }}</p>
                    </template>
                    <form autocomplete="off">
                        <div
                            v-number-format
                            v-remove-autocomplete
                            class="token__input"
                        >
                            <OrqInput
                                ref="input"
                                v-model="token"
                                :inverse="inverse"
                                :placeholder="t('token.input.placeholder')"
                                :disabled="loading"
                                max-length="6"
                                :error="!!error"
                                :description="
                                    error ? t('token.input.error') : null
                                "
                                @focus="resetInput"
                            />
                        </div>
                    </form>
                    <template v-if="type === TOKEN_TYPE.SMS">
                        <template v-if="!tokenLoading">
                            <div class="caption-xs">
                                <template v-if="timeLeft <= 0">
                                    {{ t('token.timeout') }}
                                    <a
                                        href="#"
                                        class="caption-xs"
                                        @click.stop.prevent="requestToken()"
                                        >{{ t('token.resend') }}</a
                                    >
                                </template>
                                <template v-else>
                                    {{ t('token.timer', { time: timeLeft }) }}
                                </template>
                            </div>
                        </template>
                    </template>

                    <div class="orq-modal__container__footer">
                        <div v-if="timer" class="timer-div">
                            <TimerSummary
                                v-bind="timerArgs"
                                :inverse="inverse"
                            />
                        </div>
                        <div class="orq-modal__container__footer__button-div">
                            <OrqButton
                                class="orq-button"
                                appearance="primary"
                                size="compact"
                                color="light"
                                :inverse="inverse"
                                :loading="loading"
                                :disabled="disabled"
                                :label="t('token.confirm')"
                                @click="onSubmit"
                            />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p>{{ t('token.dialog.noPhone') }}</p>
                    <div v-number-format>
                        <OrqInput
                            id="phone"
                            v-model="phone"
                            start-icon="telephony"
                            prefix="+56"
                            :max-length="9"
                            prefix-suffix-options="prefix"
                            placeholder="Celular"
                            :inverse="inverse"
                        />
                    </div>
                    <div class="orq-modal__container__footer">
                        <div class="orq-modal__container__footer__button-div">
                            <OrqButton
                                class="orq-button"
                                appearance="primary"
                                size="compact"
                                color="light"
                                :loading="updatingPhone"
                                :disabled="phone.length != 9"
                                :label="t('token.save')"
                                :inverse="inverse"
                                @click="onSubmit"
                            />
                        </div>
                    </div>
                </template>
            </OrqColumn>
        </template>
    </OrqModal>
</template>

<script setup>
const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    timer: {
        type: Boolean,
        default: false,
    },
    obol: {
        type: Boolean,
        default: false,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { OrqLockscreen } from '@orquestra-web/vue';
import { OrqModal, OrqInput, OrqColumn, OrqButton } from '@digital/orquestra';
import { useI18n } from 'vue-i18n';
import { computed, watch, ref } from 'vue';
import TimerSummary from '@/components/Timer/TimerSummary.vue';
import { numberFormat as vNumberFormat } from '@/directives/numberFormat';
import { removeAutocomplete as vRemoveAutocomplete } from '@/directives/removeAutocomplete';

import { TOKEN_TYPE } from '@/consts.js';
import { useTokenStore } from '@/_store/token.store.js';
import { storeToRefs } from 'pinia';
const tokenStore = useTokenStore();
const {
    token,
    timeLeft,
    loading: tokenLoading,
    error,
    type,
} = storeToRefs(tokenStore);
const {
    requestToken: normalRequestToken,
    requestObolToken,
    resetInput,
} = tokenStore;
const { t } = useI18n();

import { useUserStore } from '@/_store/user.store.js';
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const phone = ref('');

const disabled = computed(() => {
    return token.value.length < 6;
});

const timerArgs = ref({
    timerId: 'usdRateTimer',
    maxSeconds: 90,
    ticksPerSecond: 60,
    reversed: false,
});

const emit = defineEmits(['submit', 'cancel']);

import investmentsService from '@/_services/investments.service';
const updatingPhone = ref(false);

const onSubmit = async () => {
    if (!user.value.extraData.fonoMovil && !props.obol) {
        updatingPhone.value = true;
        await investmentsService.updatePhone(phone.value);
        // We are optimistic and if the backend returns 200 should be ok
        user.value.extraData.fonoMovil = phone.value;
        updatingPhone.value = false;
        await normalRequestToken();
    } else {
        if (disabled.value) {
            return;
        }
        emit('submit', { token, timeLeft });
    }
};

const onClose = () => {
    emit('cancel');
};

const requestToken = async () => {
    if (!props.obol) {
        if (user.value.extraData.fonoMovil) {
            await normalRequestToken();
        }
    } else {
        await requestObolToken();
    }
};

watch(
    () => props.show,
    (value) => {
        tokenStore.$reset();
        if (value === true) {
            requestToken();
        }
    }
);
</script>

<style lang="scss" scoped>
.token__input {
    max-width: 320px;
}
.timer-div {
    margin-top: 16px;
    width: 100%;
}

.inverse {
    .caption-xs,
    p {
        color: white;
    }

    a.caption-xs {
        color: var(--action-secondary-enabled-inverse);
    }

    &:deep(.orq-modal) {
        box-shadow: 0 3px 18px rgba(0, 0, 0, 0.08),
            0 12px 48px rgba(0, 0, 0, 0.24);
    }
}
</style>
