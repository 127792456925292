<template>
    <OperationCompleted v-bind="data[order.operation]" />
</template>

<script setup>
import OperationCompleted from '@/components/transactional-funds/Shared/OperationCompleted.vue';
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { OPERATION, SELL_MODES } from '@/consts.js';
import { formatDate } from '@/utils/text.js';
import { removeFormat } from '@/directives/currencyFormat';

import { useInstrumentStore } from '@/_store/instrument.store.js';
const instrumentStore = useInstrumentStore();
const { isMarketOpen, balance } = storeToRefs(instrumentStore);

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const orderStore = useOrderStore();
const { order } = storeToRefs(orderStore);

const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
});

const data = computed(() => ({
    [OPERATION.BUY]: {
        title: t('transactions.success.invest'),
        amount: order.value.simulation.inversion,
        format: order.value.simulation.moneda,
        headline: t('transactions.in', { name: props.item.info.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: false,
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: isMarketOpen.value?.open
                        ? formatDate(Date.now(), 'dayOfWeek')
                        : formatDate(
                              isMarketOpen.value?.nextOpen,
                              'dayOfWeek'
                          ) ?? '-',
                },
            },
            {
                title: t('transactions.success.execDate'),
                value: {
                    label: isMarketOpen.value?.open // TODO: use correct date
                        ? formatDate(Date.now(), 'dayOfWeek')
                        : formatDate(isMarketOpen.value?.nextOpen, 'dayOfWeek'),
                    default: '-',
                },
                tooltip: t('transactions.success.execDateTooltip'),
            },
        ],
    },
    [OPERATION.SELL]: {
        title: t('transactions.success.retrieve'),
        ...(order.value.mode === SELL_MODES.AMOUNT
            ? {
                  amount: order.value.amount,
                  format: order.value.currency,
              }
            : {
                  amount: balance.value.quantity,
                  format: 'PLAIN',
                  measure: t('transactions.quotas', balance.value.quantity),
              }),
        headline: t('transactions.of', { name: props.item.info.name }),
        outOfSchedule: !isMarketOpen.value?.open,
        cancelable: false,
        notification:
            order.value?.mode === SELL_MODES.PERCENT
                ? t('transactions.finalAmountReminder')
                : '',
        info: [
            {
                title: t('transactions.success.transactionDate'),
                value: {
                    label: isMarketOpen.value?.open
                        ? formatDate(Date.now(), 'dayOfWeek')
                        : formatDate(
                              isMarketOpen.value?.nextOpen,
                              'dayOfWeek'
                          ) ?? '-',
                },
            },
            {
                title: t('transactions.success.liquidityDate'),
                value: {
                    label: isMarketOpen.value?.open // TODO: use correct date
                        ? formatDate(Date.now(), 'dayOfWeek')
                        : formatDate(
                              isMarketOpen.value?.nextOpen,
                              'dayOfWeek'
                          ) ?? '-',
                },
                tooltip: t('transactions.success.liquidityDateTooltip'),
            },
            ...(order.value?.mode === SELL_MODES.PERCENT
                ? [
                      {
                          title: t('transactions.success.estSell'),
                          value: {
                              format: props.item?.config?.currency ?? 'CLP',
                              amount:
                                  balance.value?.amount -
                                  Number(
                                      props.item?.config?.comisionEnabled
                                          ? removeFormat(
                                                props.item?.config
                                                    ?.comisionRescate
                                            ) ?? 0
                                          : 0
                                  ),
                          },
                          showZero: true,
                      },
                  ]
                : []),
        ],
    },
}));
</script>
