<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :title="t('dap.simulationResult')"
                :subtitle="t('dap.checkResult')"
                :inverse="inverse"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyInfo :data="info" :inverse="inverse" />
                <BodyInfo :data="ratesInfo" :inverse="inverse" />
                <OrqNotificationFixed
                    v-if="!nextEnabled"
                    notification-type="fixed"
                    kind="warning"
                    :inverse="inverse"
                >
                    <span
                        v-dompurify-html="t('dap.insuficcientFunds')"
                        style="font-size: 12px"
                    />

                    <span
                        class="link"
                        style="font-size: 12px; margin-top: 8px"
                        @click="goToAddFunds"
                    >
                        Ir a abonar >
                    </span>
                </OrqNotificationFixed>
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :inverse="inverse"
                :model-value="stepIndex"
                :next-label="t('dap.accept')"
                :next-enabled="nextEnabled"
                @next="nextStep()"
                @back="prevStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqColumn,
    OrqDrawerBody,
    OrqNotificationFixed,
} from '@digital/orquestra';
import BodyInfo from '../body-components/BodyInfo.vue';
import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';

import { computed } from 'vue';
import { formatDate } from '@/utils/text';
import { storeToRefs } from 'pinia';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps, stepIndex } = storeToRefs(orderStore);
const { nextStep, prevStep } = orderStore;

const simulation = computed(() => order.value?.simulationList[0]);

const info = computed(() => {
    return [
        {
            title: t('transactions.amountToInvest'),
            value: {
                amount: simulation.value.inversion,
                format: simulation.value.moneda,
            },
        },
        {
            title: t('dap.term'),
            value: {
                label: t('dap.nDays', simulation.value.periodo),
            },
        },
        {
            title: t('dap.finalAmount'),
            value: {
                amount: simulation.value.valorFuturo,
                format: simulation.value.moneda,
                props: {
                    extraClass: `text--status-informative-base${
                        props.inverse ? '-inverse' : ''
                    }`,
                },
            },
        },
        {
            title: t('dap.profit'),
            value: {
                amount: simulation.value.ganancia,
                format: simulation.value.moneda,
                props: {
                    showAmountIndicator: true,
                    withColor: true,
                },
            },
        },
    ];
});

const ratesInfo = computed(() => {
    return [
        {
            title: t('dap.monthlyRate'),
            value: {
                amount: simulation.value.tasa,
                format: 'PERCENT',
            },
        },
        {
            title: t('dap.termRate'),
            value: {
                amount: simulation.value.tasaPeriodo,
                format: 'PERCENT',
            },
        },
        {
            title: t('dap.estExpiration'),
            value: {
                label: formatDate(
                    new Date(simulation.value.vencimiento),
                    'dayOfWeek'
                ),
            },
        },
    ];
});

const props = defineProps({
    inverse: {
        type: Boolean,
        default: false,
    },
});

import { useBalanceStore } from '@/_store/balance.store.js';
const balanceStore = useBalanceStore();
const { balance } = storeToRefs(balanceStore);

const nextEnabled = computed(() => {
    return balance.value?.CLP >= order.value.amount;
});

import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS } from '@/consts';
const rootEvents = useRootEvents();

const goToAddFunds = () => {
    rootEvents.emit(EVENTS.ABONAR_OPEN);
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
};
</script>

<style lang="scss" scoped>
.link {
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
    text-decoration-color: var(--on-color-emphasis-high-inverse);
}
</style>
