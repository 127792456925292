<template>
    <OrqColumn>
        <div
            v-currency-format.checkDecimal="
                currency === 'CLP' ? 'CLPEXT' : currency
            "
            v-focus
        >
            <OrqInput
                v-model="inputValue"
                size="large"
                :placeholder="`${FORMAT_PRESETS[currency].preffix}0`"
                :description="descriptionErrors"
                :inverse="inverse"
                :error="err"
            />
        </div>
        <span
            class="body-sm"
            :class="{
                'text--emphasis-base-medium': !inverse,
                'text--emphasis-base-medium-inverse': inverse,
            }"
        >
            {{
                t('transactions.lastPrice', {
                    price: formatCurrency(lastPrice, 'CLPEXT'),
                })
            }}
        </span>
        <table
            :class="{
                'text--emphasis-base-medium': !inverse,
                'text--emphasis-base-medium-inverse': inverse,
            }"
        >
            <tr class="caption-xs">
                <th>#</th>
                <th>{{ t('transactions.purchase') }}</th>
                <th>{{ t('transactions.sale') }}</th>
                <th>#</th>
            </tr>
            <tr
                class="caption-2xs"
                :class="{ 'text--on-color-emphasis-high': inverse }"
            >
                <td>
                    {{ formatCurrency(insPrice?.buyVolume, 'PLAIN') }}
                </td>
                <td>{{ formatCurrency(insPrice?.buyPrice, 'CLPEXT') }}</td>
                <td>{{ formatCurrency(insPrice?.sellPrice, 'CLPEXT') }}</td>
                <td>{{ formatCurrency(insPrice?.sellVolume, 'PLAIN') }}</td>
            </tr>
        </table>
    </OrqColumn>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { OrqInput, OrqColumn } from '@digital/orquestra';
import { FORMAT_PRESETS, ARCHITECTURE, TYPES, TAGS } from '@/consts';
import {
    currencyFormat as vCurrencyFormat,
    removeFormat,
    formatCurrency,
} from '@/directives/currencyFormat';
import { focus as vFocus } from '@/directives/focus';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const inputValue = ref(null);

const props = defineProps({
    currency: {
        type: String,
        default: '',
    },
    modelValue: {
        type: Number,
        default: 0,
    },
    lastPrice: {
        type: Number,
        default: 0,
    },
    minPrice: {
        type: Number,
        default: 0,
    },
    maxPrice: {
        type: Number,
        default: 0,
    },
    insPrice: {
        type: Object,
        default: () => {},
    },
    item: {
        type: Object,
        default: () => {},
    },
    validator: {
        type: Object,
        default: () => {},
    },
    error: {
        type: Boolean,
        default: null,
    },
    validMultiple: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);

watch(inputValue, (newVal, oldVal) => {
    if (oldVal !== null) {
        emit('update:modelValue', removeFormat(newVal));
    }
});

const isBolsaETF = computed(
    () =>
        props.item?.config?.tags.includes(TAGS.ETF) &&
        props.item?.architecture === ARCHITECTURE.BOLSA &&
        [TYPES.FONDO_MUTUO, TYPES.FONDO_DE_INVERSION].includes(
            props.item.info?.type?.id
        )
);

const mutipleError = computed(() => {
    return props.validator.find(
        (error) =>
            props.validator.length === 1 &&
            error?.$validator === 'multipleOfValue'
    );
});

const err = computed(() => {
    if (
        (isBolsaETF.value || props.item.info?.type.id === TYPES.ACCION) &&
        mutipleError.value
    ) {
        return mutipleError.value;
    }

    return mutipleError.value ? false : props.error;
});

const descriptionErrors = computed(() => {
    if (
        (isBolsaETF.value || props.item.info?.type.id === TYPES.ACCION) &&
        mutipleError.value
    ) {
        return props.validMultiple;
    }

    return t('transactions.limitDesc', {
        min: formatCurrency(
            props.minPrice,
            props.currency === 'CLP' ? 'CLPEXT' : props.currency
        ),
        max: formatCurrency(
            props.maxPrice,
            props.currency === 'CLP' ? 'CLPEXT' : props.currency
        ),
    });
});
</script>

<style lang="scss" scoped>
table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}
td,
th {
    text-align: center;
    padding-block: 4px;
}
tr:nth-child(2) td:nth-child(1),
tr:nth-child(2) td:nth-child(2) {
    background-color: var(--support-blue-30);
}
tr:nth-child(2) td:nth-child(3),
tr:nth-child(2) td:nth-child(4) {
    background-color: var(--support-red-30);
}
tr:nth-child(2) td:first-child {
    border-radius: 4px 0 0 4px;
}
tr:nth-child(2) td:last-child {
    border-radius: 0 4px 4px 0;
}
</style>
