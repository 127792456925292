export default {
    install: (app, options) => {
        // Listener that changes the href behaviour to router.push in all anchor tags
        document.addEventListener('click', function (event) {
            const anchor = event.target.closest('a');
            if (anchor) {
                const href = anchor.getAttribute('href');
                if (!href?.includes('http') && href !== '#') {
                    event.preventDefault(); // Prevent the default behavior of the anchor tag
                    options.router.push(href);
                }
            }
        });
    },
};
